import React from 'react';
import { Button } from '@material-ui/core/';
import ScrollLink from './ScrollLink';

function BarMenu(props) {
	const categories = props.values;
  return (
    <div>
    	{ categories.map(item => 
        <Button size='large' color="inherit">
        	<ScrollLink title={ item } />
      	</Button>
      )}
    </div>
  );
};

export default BarMenu;