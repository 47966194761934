import React from 'react';
import { Typography, Button } from '@material-ui/core/';

var s3_url = 'https://matthew-salazar-1.s3.amazonaws.com/static/';

var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
 		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function PaperType(props) {
	var t = new Date(props.item.date_pub);
	var date = monthNames[t.getMonth()]+' '+t.getFullYear();

	return (
		<div>
			<Typography variant='h5'>
				{ props.item.authors } { date }
			</Typography>
			<div style={{ height: 20 }}/>
			<Typography
				variant='h5' 
				style={{ textAlign: 'left' }}
			>
				{ props.item.easy_desc }
			</Typography>
			<div style={{ height: 20 }}/>
			<Button 
				size='large'
				color="inherit"
				variant='outlined'
				target="_blank"
          		rel="noopener noreferrer"
				title = {`${ props.item.title }`}
				href={`${ s3_url }${ props.item.pdf }`}
			>
        		Read Paper
      		</Button>
		</div>
	);
}

function JobType(props) {
	var t1 = new Date(props.item.date_start);
	var date1 = monthNames[t1.getMonth()]+' '+t1.getFullYear();

	var date2;
	if (props.item.currently_working) {
		date2 = 'Present';
	} else {
		var t2 = new Date(props.item.date_end);
		date2 = monthNames[t2.getMonth()]+' '+t2.getFullYear();
	}

	var link;

	if (props.item.hasLink) {
		link = 	<div>
					<div style={{ height: 20 }}/>
						<Button 
							size='large'
							color="inherit"
							variant='outlined'
							target="_blank" 
							rel="noopener noreferrer"
							title = {`${ props.item.company_name }`}
							href={ props.item.link }
						>
				        	Company Website
				    	</Button>
				    </div>
	} else {
		link = <div />
	}

	return (
		<div>
			<Typography variant='h5'>
				{ date1 } - { date2 }
			</Typography>
			<div style={{ height: 20 }}/>
			<Typography 
				variant='h5'
				style={{ textAlign: 'left' }}
			>
				{ props.item.company_desc }
			</Typography>
			<div style={{ height: 20 }} />
			<Typography
				variant='h5' 
				style={{ textAlign: 'left' }}
			>
				{ props.item.easy_desc }
			</Typography>
			{link}
		</div>
	);
}

function ProjectType(props) {
	var t = new Date(props.item.date);
	var date = monthNames[t.getMonth()]+' '+t.getFullYear();

	var link;

	if (props.item.hasLink) {
		link = 	<div>
					<div style={{ height: 20 }}/>	
					<Button 
						size='large'
						color="inherit"
						variant='outlined'
						href={ props.item.link }
					>
			    		Project Link
				    </Button>
				</div>
	} else {
		link = <div />
	}

	return (
		<div>
			<Typography variant='h5'>
				Received { props.item.awards } in { date }
			</Typography>
			<div style={{ height: 20 }} />
			<Typography
				variant='h5' 
				style={{ textAlign: 'left' }}
			>
				{ props.item.easy_desc }
			</Typography>
			{link}
		</div>
	);
}

function ModalType(props) {
	var otherFields;
	if ( props.item.modelType === 'paper') {
		otherFields = <PaperType item={ props.item } />
	} else if ( props.item.modelType === 'work') {
		otherFields = <JobType item={ props.item } />
	} else if ( props.item.modelType === 'project') {
		otherFields = <ProjectType item={ props.item } />
	} else {
		otherFields = <div></div>
	}

	return (
		<div style={{ margin: '2vw 3vw' }}>
			<Typography variant='h2'>
				{ props.item.title }
			</Typography>
			{ otherFields }
		</div>
	);
};

export default ModalType;