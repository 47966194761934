import React from 'react';
import { AppBar, Toolbar, Button, Hidden, Typography } from '@material-ui/core/';
import { animateScroll as scroll } from 'react-scroll';
import DropMenu from './DropMenu';
import BarMenu from './BarMenu';

function scrollToTop() {
  scroll.scrollToTop({duration: 550, delay: 0});
};

var categories = [
  'Experience',
  'Research',
  'Projects'
]

function NavBar() {
  return (
    <AppBar color='default' position='fixed' style={{alignItems: 'center'}}>
      <Toolbar variant='dense'>
        <Button size='large' onClick={ scrollToTop } color="inherit">
          <Typography variant='subtitle1'>Matthew N Salazar</Typography>
        </Button>
        <Hidden only="xs">
          <BarMenu values={categories} />
        </Hidden>
        <Hidden smUp>
          <DropMenu values={categories} />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;