import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, Fade, Hidden,
         Modal, Typography } from '@material-ui/core/';
import ModalType from './ModalType';

var s3_url = 'https://matthew-salazar-1.s3.amazonaws.com/static/';

const modalStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'block',
  textAlign: 'center',
  position: 'absolute',
  width: '85vw',
  maxHeight: '90%',
  overflow: 'auto',
  backgroundColor: 'white',
  padding: 2,
};

function CardLink(props) {
	const [checked, setChecked] = useState(false);

  const handleOff = () => {
    setChecked(false);
  };

  const handleOn = () => {
    setChecked(true);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Hidden smUp>
        <div style={{ height: 5 }} />
      </Hidden>
      <Card
      	style={{borderRadius: 0, position: 'relative'}}
				onMouseEnter={handleOn}
				onMouseLeave={handleOff}
        onTouchStart={handleOn}
        onTouchEnd={handleOff}
        onClick={handleOpen}
			>
        <CardMedia
          style={{borderRadius: 0, height: 0, paddingTop: '56.25%'}}
          image={`${s3_url}${ props.item.img }`}
          title={ props.item.title }
        />
        <Fade in={checked}>
        	<div style={{position: 'absolute', top:'0px', height: '100%', 
        		width: '100%', background: 'rgba(0, 0, 0, 0.7)', display: 'flex',
    				alignItems: 'center', justifyContent: 'center'}}
        	>
        		<Typography variant='h4' style={{maxHeight: '90%', color: 'white',	
            margin: '10px'}}
            >
        			{ props.item.title }
        		</Typography>
	       	</div>
		  	</Fade>
      </Card>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
        outline='none'
        disableAutoFocus
      >
        <div style={modalStyle}>
          <ModalType item={ props.item } />
        </div>
      </Modal>
      <Hidden smUp>
        <div style={{ height: 5 }} />
      </Hidden>
    </div>
  )
}

function List(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`api/${props.type}/`)
      .then(res => {
        setData(res.data);
      })
  }, [props]);

  return ( 
    <Grid container spacing={0} justify="center" alignItems="stretch">
      { data.map(item => 
        <Grid key={ item.id } item xs={12} sm={6} md={4} lg={4} xl={3}>
        	<CardLink item={item} />
        </Grid>
      )}
    </Grid>
  );
}

export default List;