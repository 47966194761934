import React from 'react';
import { Divider, Hidden, Typography } from '@material-ui/core/';
import { List, NavBar } from './components/index';
import { Element } from 'react-scroll';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';

const general = {
  textAlign: 'center',
  width: '98.5vw',
  margin: 'auto',
  padding: '20',
  overflow: 'hidden'
};

let theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: '9.5vw',
      '@media (min-width:600px)': {
        fontSize: '3.9rem',
      },
      '@media (max-width:450px)': {
        fontSize: '2.9rem',
      },
      fontFamily: 'Playfair Display',
      padding: '10px',
      color: '#202020'
    },
    h2: {
      fontSize: '6.5vw',
      '@media (min-width:600px)': {
        fontSize: '3.2rem',
      },
      '@media (max-width:450px)': {
        fontSize: '2.2rem',
      },
      fontFamily: 'Playfair Display',
      padding: '5px',
      color: '#202020'
    },
    h3: {
      fontSize: '4.5vw',
      '@media (min-width:600px)': {
        fontSize: '2.2rem',
      },
      '@media (max-width:450px)': {
        fontSize: '1.5rem',
      },
      fontFamily: 'Playfair Display',
      lineHeight: 1.6,
      color: '#404040'
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 900,
      fontFamily: 'Montserrat',
      lineHeight: 1.6,
      color: '#404040'
    },
    h5: {
      fontSize: '3vw',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      '@media (max-width:450px)': {
        fontSize: '1.1rem',
      },
      fontFamily: 'Montserrat',
      lineHeight: 1.6,
      padding: '5px',
      color: '#404040'
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      color: 'gray'
    },
    button: {
      fontFamily: 'Montserrat'
    }
  }
});

function Welcome() {
  return (
    <div>
      <Typography variant='h2'>Matthew Salazar, FRM</Typography>
      <div style={{ height: 15 }} />
      <div>
        <a 
          href='https://matthew-salazar-1.s3.amazonaws.com/static/resume_active.pdf' 
          target="_blank" 
          rel="noopener noreferrer" 
          display = 'inline'
          title = 'Resumé'
        >
          <i display = 'inline' class='fa fa-file-pdf-o fa-2x'></i>
        </a>
        <Typography variant='h4' display = 'inline'>&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
        <a 
          href='https://www.linkedin.com/in/matthew-salazar-871805120/' 
          target="_blank" 
          rel="noopener noreferrer" 
          display = 'inline'
          title = 'LinkedIn Profile'
        >
          <i display = 'inline' class='fa fa-linkedin-square fa-2x'></i>
        </a>
      </div>
      <div style={{ height: 15 }} />
      <Typography variant='h5'>Investment Analyst @ Cornell Endowment</Typography>
      <Typography variant='h5'>Cornell Dec 2019 | BA in Math Cum Laude | FRM</Typography>
    </div>
  )
}

function SectionTitle(props) {
  return (
    <Element name={props.text} className='element' >
      <Hidden only='xs'>
        <div style={{ height: 10 }} />
      </Hidden>
      <div style={{ height: 25 }} />
      <Divider light variant='middle' />
      <div style={{ height: 25 }} />
      <Typography variant='h2'>{props.text}</Typography>
      <div style={{ height: 25 }} />
    </Element>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={general}>
        <div style={{ height: 75 }} />
        <NavBar />
        <Fade left>
          <Welcome />
        </Fade>
        <Fade right>
          <SectionTitle text='Experience' />
          <Typography 
            variant='h5'
            style={{ textAlign: 'left', paddingRight: '5vw', paddingLeft: '5vw' }}
          >
            I currently work at the Cornell Endowment as an Investment Analyst. Previously, I have 
            interned as a teacher at South Bronx Classical Charter and as a VC intern at H//NGE 
            Capital. I also had hands-on experience in finance clubs at Cornell. Outside of 
            finance, I am passionate about education, mentorship, and technology.
          </Typography>
        </Fade>
        <div style={{ height: 25 }} />
        <Fade bottom>
          <List type='job' />
        </Fade>
        <Fade left>
          <SectionTitle text='Research' />
          <Typography 
            variant='h5'
            style={{ textAlign: 'left', paddingRight: '5vw', paddingLeft: '5vw' }}
          >
            Throughout my academic career, I challenged myself by taking classes that required innovative thinking
            and rigorous understanding. These classes spanned many fields including neurobiology, computer science,
            mathematics, economics, and linguistics. At Cornell, I graduated cum laude with a BA with distinction 
            in math and as a member of the International Economics Honor Society.
          </Typography>
        </Fade>
        <div style={{ height: 25 }} />
        <Fade bottom>
          <List type='paper' />
        </Fade>
        <Fade right>
          <SectionTitle text='Projects' />
          <Typography 
            variant='h5'
            style={{ textAlign: 'left', paddingRight: '5vw', paddingLeft: '5vw' }}
          >
            Outside of work and academia, I enjoy competing in hackathons and other contests, receiving
            multiple awards. Teamwork is central to me in any project and is reflected in my approach to these 
            competitions.
          </Typography>
        </Fade>
        <div style={{ height: 25 }} />
        <Fade bottom>
          <List type='hackathon' />
        </Fade>
        <div style={{ height: 25 }} />
      </div>
    </ThemeProvider>
  )
};

export default App;