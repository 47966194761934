import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import ScrollLink from './ScrollLink';

function DropMenu(props) {
  const categories = props.values;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton edge="start" aria-controls="simple-menu" 
        aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounteds
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant='menu'
      >
        { categories.map(item => 
          <MenuItem>
            <ScrollLink title={ item } />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default DropMenu;