import React from 'react';
import { Typography } from '@material-ui/core/';
import { Link } from 'react-scroll';

function ScrollLink(props) {
  return (
    <Link activeClass="active" to={props.title} 
          spy={true} smooth={true} delay={0} 
          duration={550} offset={-30}
    >
      <Typography variant='subtitle1'>{props.title}</Typography>
    </Link>
  );
};

export default ScrollLink;